/* eslint-disable react/display-name */
import getStore from '@redux/store';
import React from 'react';
import { Provider } from 'react-redux';
// Functional HOC
export default function (WrappedComponent) {
    return function (props) {
        const store = getStore(props.reduxContext);
        return (React.createElement(Provider, { store: store },
            React.createElement(WrappedComponent, { ...props })));
    };
}
