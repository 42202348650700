export const pageContextInitialState = {
    documentCulture: '',
    documentGuid: '',
    nodeId: -1,
    nodeAliasPath: '',
};
export default function pageContextReducer(state = pageContextInitialState) {
    return {
        ...state,
    };
}
