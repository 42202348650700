const LOG_IN = 'APP/LOG_IN';
const LOG_OUT = 'APP/LOG_OUT';
const SET_LOGIN_MODAL = 'APP/SET_LOGIN_MODAL';
const SET_WELCOME_MESSAGE = 'APP/SET_WELCOME_MESSAGE';
export var LoginRedirectType;
(function (LoginRedirectType) {
    LoginRedirectType[LoginRedirectType["Default"] = 0] = "Default";
    LoginRedirectType[LoginRedirectType["TechnicalLibrary"] = 1] = "TechnicalLibrary";
})(LoginRedirectType || (LoginRedirectType = {}));
export const appInitialState = {
    customerId: '',
    isLoggedIn: false,
    loginModalType: undefined,
    loginUrl: '#',
    registerUrl: '#',
    myProfileUrl: '',
    myHiresUrl: '',
    emailAddress: null,
    checkoutConfirmationUrl: '',
    checkoutDetailsUrl: '',
    checkoutOverviewUrl: '',
    checkoutPaymentUrl: '',
    welcomeMessage: null,
    basketItemsCount: 0,
    excludedDates: [],
    redirectUrl: '',
};
export default function appReducer(state = appInitialState, action) {
    switch (action.type) {
        case 'APP/LOG_IN': {
            const newState = { ...state };
            newState.isLoggedIn = true;
            return newState;
        }
        case 'APP/LOG_OUT': {
            const newState = { ...state };
            newState.isLoggedIn = false;
            return newState;
        }
        case 'APP/SET_LOGIN_MODAL': {
            const newState = { ...state };
            newState.loginModalType = action.payload.loginType;
            if (action.payload.redirectUrl) {
                newState.redirectUrl = action.payload.redirectUrl;
            }
            return newState;
        }
        case 'APP/SET_WELCOME_MESSAGE': {
            const newState = { ...state };
            newState.welcomeMessage = action.payload;
            return newState;
        }
        default: {
            return state;
        }
    }
    return state;
}
