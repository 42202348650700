import { combineReducers } from 'redux';
import appReducer from './app/app.reducer';
import basketReducer from './basket/basket.reducer';
import customerPortalReducer from './customerportal/customerportal.reducer';
import pageContextReducer from './pagecontext/pagecontext.reducer';
import userReducer from './user/user.reducer';
export default combineReducers({
    app: appReducer,
    basket: basketReducer,
    pageContext: pageContextReducer,
    customerPortal: customerPortalReducer,
    user: userReducer,
});
