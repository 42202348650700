const SET_BASKET = 'BASKET/SET_BASKET';
const SET_LOADING = 'BASKET/SET_LOADING';
const SET_ENQUIRY_BASKET = 'BASKET/SET_ENQUIRY_BASKET';
const SHOW_ENQUIRY_BASKET_POPUP = 'BASKET/SHOW_ENQUIRY_BASKET_POPUP';
export let basketInitialState = {
    loading: 'none',
    basket: null,
    enquiryBasket: null,
    showEnquiryBasketPopup: false,
    enquiryBasketTranslations: {},
};
export function setInitialBasketState(data) {
    basketInitialState = {
        ...basketInitialState,
        ...data,
    };
}
export default function basketReducer(state = basketInitialState, action) {
    switch (action.type) {
        default: {
            return state;
        }
        case 'BASKET/SET_LOADING': {
            const newState = { ...state };
            newState.loading = action.payload;
            return newState;
        }
        case 'BASKET/SET_BASKET': {
            const newState = { ...state };
            newState.basket = action.payload;
            return newState;
        }
        case 'BASKET/SET_ENQUIRY_BASKET': {
            const newState = { ...state };
            newState.enquiryBasket = action.payload;
            if (action.payload === null || JSON.stringify(action.payload) === undefined) {
                localStorage.removeItem('ENQUIRY_BASKET');
            }
            else {
                localStorage.setItem('ENQUIRY_BASKET', JSON.stringify(action.payload));
            }
            return newState;
        }
        case 'BASKET/SHOW_ENQUIRY_BASKET_POPUP': {
            const newState = { ...state };
            newState.showEnquiryBasketPopup = action.payload;
            return newState;
        }
    }
}
