const SET_DESKTOP_NAV = 'CUSTOMER_PORTAL/SET_DESKTOP_NAV';
const SET_MOBILE_NAV = 'CUSTOMER_PORTAL/SET_MOBILE_NAV';
export const customerPortalInitialState = {
    desktopNavOpen: true,
    mobileNavOpen: false,
};
export default function customerPortalReducer(state = customerPortalInitialState, action) {
    switch (action.type) {
        default: {
            return state;
        }
        case 'CUSTOMER_PORTAL/SET_DESKTOP_NAV': {
            const newState = { ...state };
            newState.desktopNavOpen = action.payload;
            return newState;
        }
        case 'CUSTOMER_PORTAL/SET_MOBILE_NAV': {
            const newState = { ...state };
            newState.mobileNavOpen = action.payload;
            return newState;
        }
    }
}
